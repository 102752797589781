.tooltip
  cursor   : pointer
  display  : inline-block
  margin   : 0px 10px
  position : relative

  .material-icons
    color     : $gray
    font-size : 17px

  .info
    background  : white
    border      : 1px solid #EEE
    box-shadow  : 0 5px 18px 0 rgba(black, 0.12)
    color       : $medium-gray
    font-size   : 16px
    font-weight : bold
    left        : calc(50% - 150px)
    line-height : 1
    opacity     : 0
    padding     : 20px 25px
    position    : absolute
    top         : 100%
    transition  : all 0.3s
    visibility  : hidden
    width       : 300px
    z-index     : 1

    &.active
      opacity : 1
      visibility : visible

    &:before
      +triangle('up', 24px, 8px, #EEE)
      content  : ''
      position : absolute
      top      : -8px
      left     : calc(50% - 12px)

    &:after
      +triangle('up', 22px, 7px, white)
      content  : ''
      position : absolute
      top      : -7px
      left     : calc(50% - 11px)


