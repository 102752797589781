.select
  height   : 48px
  margin   : 32px 0
  position : relative

  .trigger
    align-items      : center
    background-color : white
    border           : 1px solid $light-gray
    border-radius    : $base-border-radius
    cursor           : pointer
    display          : flex
    flex-direction   : row
    font-weight      : bold
    line-height      : 19px

    > *
      margin : 13px 16px

  .material-icons
    margin-left : auto

  input
    filter   : alpha(opacity=0)
    left     : 0
    opacity  : 0
    position : absolute
    top      : 0
    z-index  : -1

  .options
    background-color : white
    border           : 1.5px solid #E5E5E5
    border-radius    : $base-border-radius
    box-shadow       : 0 5px 18px 0 rgba(black, 0.12)
    box-shadow       : 0 2px 4px 0 rgba(black, 0.12), 0 0 1px 0 rgba(black, 0.12)
    opacity          : 0
    overflow-y       : scroll
    max-height       : 325px
    padding          : 32px
    padding-bottom   : 73px
    position         : relative
    transition       : all 0.3s
    visibility       : hidden

    &.active
      opacity    : 1
      visibility : visible
      z-index    : 1

  footer
    background : white
    border-top : 1px solid $light-gray
    bottom     : 0
    left       : 0
    right      : 0

    a
      align-items     : center
      cursor          : pointer
      display         : flex
      font-weight     : 600
      padding         : 24px 32px

      .material-icons
        font-size    : 20px
        margin-left  : 0
        margin-right : 8px

  .option-group
    margin-bottom : 28px
    text-align    : left

    &:last-child
      margin-bottom : 0

    label
      line-height   : 25px
      margin-bottom : 16px

  .option
    cursor        : pointer
    font-weight   : bold
    line-height   : 25px
    margin-bottom : 4px
