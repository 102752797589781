$students-border: 1.5px solid $light-gray

#section-form
  margin-bottom: 110px

  .section-name
    float: left
    width: 50%

    label
      font-size: 40px

    input
      background: transparent
      color: $dark-gray
      font-size: 40px
      font-weight: 500
      line-height: 48px
      padding: 10px

      &:focus ~ label,
      &:valid ~ label,
      &.valid ~ label
        font-size: 12px

  .search
    float: right
    margin-top: 64px
    position: relative
    width: 25.5%

    .material-icons
      color: $slate
      font-size: 20px
      left: 16px
      position: absolute
      top: calc(50% - 10px)

    input
      color: $slate
      font-size: 14px
      font-weight: 600
      line-height: 17px
      margin: 0
      padding: 15px 15px 15px 56px

  .section-options
    clear: both
    label
      div
        display: inline-block
        margin-left: 20px
    div
      margin-left: 40px
      p
        margin-top: 25px
    img
      margin-top: 25px

  tbody td:nth-child(2)
    color: $dark-gray
    font-size: 16px
    font-weight: bold
    line-height: 25px

  .section-footer
    background: #454545
    bottom: 0
    color: white
    left: 0
    padding: 16px 0
    position: fixed
    right: 0
    width: 100%

    .container
      +container
      float: none
      align-items: center
      display: flex
      flex-direction: row
      justify-content: flex-end

    .content
      +grid-column(12)
      +grid-collapse
      align-items: center
      display: flex
      flex-direction: row
      justify-content: flex-end

      .add-user
        width: 50%
        margin-right: 20px

      .count
        font-size: 20px
        font-weight: 500
        line-height: 24px

      button
        margin-left: 16px

.react-toggle
  touch-action: pan-x

  display: inline-block
  position: relative
  cursor: pointer
  background-color: transparent
  border: 0
  padding: 0

  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  -webkit-tap-highlight-color: rgba(0,0,0,0)
  -webkit-tap-highlight-color: transparent

  &-screenreader-only
    border: 0
    clip: rect(0 0 0 0)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute
    width: 1px

  &--disabled
    cursor: not-allowed
    opacity: 0.5
    -webkit-transition: opacity 0.25s
    transition: opacity 0.25s

  &-track
    width: 50px
    height: 24px
    padding: 0
    border-radius: 30px
    background-color: #178B88
    -webkit-transition: all 0.2s ease
    -moz-transition: all 0.2s ease
    transition: all 0.2s ease

  &:hover:not(.react-toggle--disabled) .react-toggle-track
    background-color: #178B88

  &--checked .react-toggle-track
    background-color: #19AB27

  &--checked:hover:not(.react-toggle--disabled) .react-toggle-track
    background-color: #128D15

  &-track-check
    position: absolute
    width: 14px
    height: 10px
    top: 0px
    bottom: 0px
    margin-top: auto
    margin-bottom: auto
    line-height: 0
    left: 8px
    opacity: 0
    -webkit-transition: opacity 0.25s ease
    -moz-transition: opacity 0.25s ease
    transition: opacity 0.25s ease

  &--checked .react-toggle-track-check
    opacity: 1
    -webkit-transition: opacity 0.25s ease
    -moz-transition: opacity 0.25s ease
    transition: opacity 0.25s ease

  &-track-x
    position: absolute
    width: 10px
    height: 10px
    top: 0px
    bottom: 0px
    margin-top: auto
    margin-bottom: auto
    line-height: 0
    right: 10px
    opacity: 1
    -webkit-transition: opacity 0.25s ease
    -moz-transition: opacity 0.25s ease
    transition: opacity 0.25s ease

  &--checked .react-toggle-track-x
    opacity: 0

  &-thumb
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms
    position: absolute
    top: 1px
    left: 1px
    width: 22px
    height: 22px
    border: 1px solid #4D4D4D
    border-radius: 50%
    background-color: #FAFAFA

    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box

    -webkit-transition: all 0.25s ease
    -moz-transition: all 0.25s ease
    transition: all 0.25s ease

  &--checked .react-toggle-thumb
    left: 27px
    border-color: #19AB27

  &--focus .react-toggle-thumb
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0
    -moz-box-shadow: 0px 0px 3px 2px #0099E0
    box-shadow: 0px 0px 2px 3px #0099E0

  &:active:not(.react-toggle--disabled) .react-toggle-thumb
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0
    -moz-box-shadow: 0px 0px 5px 5px #0099E0
    box-shadow: 0px 0px 5px 5px #0099E0

.hidden
  display: none
