#groups
  +container
  padding-top: 56px

  .header
    align-items: center
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 72px

    h1, h2
      margin: 0

    .actions a, .actions button
      margin-left: 10px

  .container
    +grid-column(10)
    +grid-push(2)

  .group-list

    h2
      border-bottom: 1px solid $light-gray
      margin-bottom: 24px
      padding-bottom: 16px

  .group-list-section
    margin-bottom: 56px
