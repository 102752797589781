#account-profile,
#account-password,
#account-notification
  +container
  padding-top : 56px

  .container
    +grid-column(10)
    +grid-push(2)

    &.flex
      align-items     : center
      display         : flex
      flex-direction  : column
      justify-content : center

#account-notification
  form
    margin-top : 26px

    .button
      margin-top : 40px
