.assignment-form

  .fields
    margin : 0 8px

  .field:not(.inline)
    label
      color       : $medium-gray
      font-size   : 12px
      font-weight : bold
      line-height : 14px

  .assignment-datetime
    display         : flex
    justify-content : space-between

  .datetime, .date, .time
    margin-bottom : 40px
    position      : relative
    width         : 60%

    &:before
      content        : 'event'
      font-family    : 'Material Icons'
      font-size      : 20px
      pointer-events : none
      position       : absolute
      top            : 18px
      left           : 5px

    input
      color        : $gray
      font-size    : 16px
      font-weight  : bold
      line-height  : 19px
      padding      : 16px
      padding-left : 30px

      &::placeholder
        color       : $gray
        font-size   : 16px
        font-weight : bold
        line-height : 19px

  .datetime
    width           : 100%

    &:before
      top           : 30px

  .time
    width           : 35%

    &:before
      content       : 'access_time'

    input
      margin-top    : 5px

  .disabled
    color      : $light-gray

    input
      color        : $light-gray

    input:hover, input:focus, input:active
      border-color : $light-gray
      box-shadow   : none

  .field.inline label
    color         : $gray
    font-size     : 16px
    font-weight   : bold
    line-height   : 25px
    margin-bottom : 24px

  .now-or-later
    display         : flex
    justify-content : space-around

  .field.now-or-later.small-label, .field.due-date.small-label
      color           : $medium-gray
      font-size       : 12px
      font-weight     : bold
      line-height     : 14px
      justify-content : flex-start
      align-items     : flex-end

  .field.now-or-later
    width         : 40%

  .field.due-date
    width         : 100%

  .field.now-or-later-inputs
    width         : 25%
    justify-content : flex-end

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after
      background    : $teal
      content       : ''
      height        : 10px
      left          : 7px
      position      : absolute
      top           : 7px
      transition    : all .3s
      width         : 10px
      border-radius : 8px

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before
      border-radius: 13px

    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before
      content: ''
      height: 18px
      left: 3px
      position: absolute
      top: 3px
      width: 18px

    label
      color         : $gray
      font-size     : 14px
      font-weight   : bold
      line-height   : 25px
      height        : 25px
      display       : inline
      margin        : 0 10px
      padding-left  : 30px
      float         : right


  .actions
    align-items    : center
    display        : flex
    flex-direction : column
    margin         : 0 8px

    .cancel
      color       : $slate
      cursor      : pointer
      font-size   : 14px
      font-weight : 600
      line-height : 17px
      margin-top  : 16px

    button
      margin-top : 64px
      width      : 100%

#project-sidebar .assignment-form .heading
  border-bottom : 0

  h1
    font-size: 18px
    line-height: 25px
