.subnav
  margin-bottom: 79px
  width: 100%

  a
    color: $gray
    display: inline-block
    font-size: 14px
    font-weight: normal
    line-height: 17px
    margin-right: 56px

    &:hover
      color: lighten($gray, 15)

    &.active
      color: $teal
      font-weight: bold
