table {
  border-collapse: collapse;
  margin: $base-spacing 0;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}

thead {
  line-height: $heading-line-height;
  vertical-align: bottom;
}

tbody {
  vertical-align: top;

  tr.disabled {
    td {
      color : rgba($gray, 0.5)
    }
  }
}

tr {
  border-bottom: $base-border;
}

th {
  font-weight: 600;
}

th,
td {
  padding: $small-spacing $small-spacing $small-spacing 0;
}

td.center {
  text-align: center;
}
