#student-dashboard,
#teacher-dashboard,
#school-admin-dashboard
  +container
  padding-top: 56px

  .add-section-by-code
    display: flex
    flex-direction: row
    justify-content: space-evenly
    float: right
    width: 300px
    margin-top: -50px

    input
      width: 140px
      color: $slate
      font-size: 14px
      font-weight: 600
      line-height: 17px
      margin: 0
      padding: 15px
      text-transform: uppercase

  .header
    align-items: center
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-bottom: 72px

    h1, h2
      margin: 0

    .actions a, .actions button
      margin-left: 10px

  .container
    +grid-column(10)
    +grid-push(2)

    &.flex
      display: flex
      flex-direction: column
      justify-content: center

    &.flash .flash-message
      margin-bottom: 56px
      width: 100%

      &.hidden
        margin: 0
