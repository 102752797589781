#project-form
  +container

  form
    +grid-column(8)
    margin-top    : $header-height
    margin-bottom : $header-height

  .header
    align-items     : flex-end
    display         : flex
    flex-direction  : row
    justify-content : space-between

    button
      font-size      : 14px
      font-weight    : bold
      letter-spacing : normal
      line-height    : 17px
      text-align     : center
      text-transform : none

  .subheader
    margin-top : 25px

    a, .sections-list
      align-items : center
      cursor      : pointer
      display     : flex

      .material-icons
        font-size    : 20px
        margin-right : 8px

    .sections-list
      padding-left: 10px

  .project-name
    flex          : 1
    margin        : 54px 40px 0 0

    input, .title
      background  : transparent
      color       : $purple
      font-size   : 40px
      font-weight : 500
      line-height : 48px
      padding     : 10px

  .project-queue
    background-color : white
    border-radius    : 4px
    box-shadow       : 0 2px 4px 0 rgba(black, 0.12), 0 0 1px 0 rgba(black, 0.12)
    margin           : 40px 0
    padding          : 40px 24px

    .header-with-pie-chart
      position: relative

      .pie-icon
        position: absolute
        top: 2px
        left: 200px
        display: flex
        flex-direction: row

        img
          width: 20px
          height: 20px

        span
          color: $purple
          text-decoration: underline
          padding-left: 5px
          font-size: 14px

      .learner-type-chart
        position: absolute
        background: white
        pointer-events: none
        top: -100px
        left: 25%
        z-index: 1
        padding: 20px
        box-shadow: 0 2px 4px 0 rgba(black, 0.12), 0 0 1px 0 rgba(black, 0.12)
        width: 400px

        .chart-container
          width: 200px
          float: left


        .legend
          display: block
          float: left

          h5
            color: $medium-gray
            font-size: 16px

          .legend-item
            font-size: 14px

            .legend-circle
              display: inline-block
              width: 10px
              height: 10px
              margin-right: 10px
              border-radius: 5px


    h2
      font-weight   : 600
      margin-bottom : 8px

    p
      color       : $gray
      line-height : 25px

    hr
      margin : 40px 0

    .message
      opacity : 0.65

    li
      background-color : transparent
      padding          : 6px 0

    .drop
      background-color : $soft-white
      border           : 1.5px dashed $light-gray
      border-radius    : $base-border-radius
      padding          : 13px 16px
      transition       : all 0.3s

      &.isOver
        background-color : white
        border-color     : $teal

