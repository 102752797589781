#admin-dashboard,
#admin-quotes,
#admin-banks,
#admin-organizations,
#organization-report,
#school-admin-users,
#admin-questions,
#admin-files,
#admin-users
  +container
  padding-top : 56px

  .search
    float      : right
    margin-top : -15px
    position   : relative
    width      : 25.5%

    .material-icons
      color     : $slate
      font-size : 20px
      left      : 16px
      position  : absolute
      top       : calc(50% - 10px)

    input
      color       : $slate
      font-size   : 14px
      font-weight : 600
      line-height : 17px
      margin      : 0
      padding     : 15px 15px 15px 56px

  .container
    +grid-column(10)
    +grid-push(2)

    &.flex
      display         : flex
      flex-direction  : column
      justify-content : center

    &.flash .flash-message
      margin-bottom : 56px
      width         : 100%

      &.hidden
        margin : 0

  .header
    align-items     : center
    display         : flex
    flex-direction  : row
    justify-content : space-between
    margin-bottom   : 72px

    h1, h2
      margin : 0

    .actions a, .actions button
      margin-left : 10px

  .well
    h2
      text-align : center

  form
    button:not([value])
      width : 100%

  #user_graduation_choices
    margin-bottom : 24px

  .bulk-action-checkbox + label
    &:before
      top : -12px

    &:after
      top : -8px

  .learner-type-chart
    width: 450px
    height: 157px

    .chart-container
      width: 200px
      height: 157px
      display: block
      float: left

    .legend
      display: block
      float: left
      margin-left: 50px

      h5
        color: $medium-gray
        font-size: 16px

      .legend-item
        font-size: 14px

        .legend-circle
          display: inline-block
          width: 10px
          height: 10px
          margin-right: 10px
          border-radius: 5px
