html
  color       : $base-font-color
  font-family : $base-font-family
  font-size   : 100%
  line-height : $base-line-height

h1, h2, h3, h4, h5, h6
  font-family : $heading-font-family
  font-size   : modular-scale(1)
  line-height : $heading-line-height
  margin      : 0 0 $small-spacing

h1
  font-size     : 40px
  font-weight   : 500
  line-height   : 48px
  margin-bottom : 48px

h2
  font-weight   : 500

p
  margin : 0 0 $small-spacing

a, .link
  color                : $action-color
  cursor               : pointer
  text-decoration      : none
  text-decoration-skip-ink: auto
  transition           : color $base-duration $base-timing

  &:hover
    color : shade($action-color, 25%)

  &:focus
    outline        : $focus-outline
    outline-offset : $focus-outline-offset

    &.focus--mouse
      outline : 0

hr
  border-bottom : $base-border
  border-left   : 0
  border-right  : 0
  border-top    : 0
  margin        : $base-spacing 0
  width         : 100%

mark
  background-color: $yellow

.bold
  font-weight   : 600