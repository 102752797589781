$zinc-50: #fafafa
$zinc-100: #f0f0f3
$zinc-200: #e0e0e5
$zinc-700: #5f6368
$zinc-900: #32373c

.table-container
	overflow-y: auto
	padding-left: 1rem
	padding-right: 1rem
	.table
		width: 40rem
		margin-top: 2.75rem

@media (min-width: 640px)
	.table-container
		overflow: visible
		padding-left: 0
		padding-right: 0
		.table
			width: 100%
			thead
				font-size: 0.875rem
				text-align: left
				line-height: 1.5
				color: #8B949E
				.col-th
					padding: 0
					padding-bottom: 1rem
					padding-right: 1.5rem
					font-weight: normal
				.action-th
					position: relative
					padding: 0
					padding-bottom: 1rem
			tbody
				position: relative
				> *
					border-color: $zinc-200
					border-top-width: 1px
					border-color: $zinc-100
					border-top-width: 1px
					border-color: $zinc-200
					font-size: 0.9rem
					line-height: 1.5
					color: $zinc-700
				tr
					position: relative
					&.group
						&:hover
							background-color: $zinc-50
					td
						position: relative
						padding: 0
						&.cursor-pointer
							cursor: pointer
						div
							display: block
							padding-top: 1rem
							padding-bottom: 1rem
							padding-right: 1.5rem
							.one
								position: absolute
								top: 0
								bottom: 0
								right: 0
								left: -1rem
								&.group
									&:hover
										background-color: $zinc-50
							.semibold
								font-weight: 600
								color: $zinc-900
					.action
						position: relative
						width: 3.5rem
						padding: 0
						div
							position: relative
							white-space: nowrap
							padding-top: 1rem
							padding-bottom: 1rem
							text-align: right
							font-size: 0.875rem
							font-weight: 500
							span
								position: absolute
								top: 0
								bottom: 0
								right: 0
								left: -1rem
								&.group
									&:hover
										background-color: $zinc-50
							.slot
								position: relative
								margin-left: 1rem
								font-weight: 600
								line-height: 1.5
								color: $zinc-900
								&:hover
									color: $zinc-700
								.sr-only
									position: absolute
									width: 1px
									height: 1px
									padding: 0
									margin: -1px
									overflow: hidden
									clip: rect(0, 0, 0, 0)
									white-space: nowrap
									border: 0
