.full-modal
  background : $soft-white
  bottom     : 0
  left       : 0
  position   : fixed
  right      : 0
  top        : 0

  .full-modal-header
    background    : white
    border-bottom : 1px solid $light-gray
    color         : $purple

    nav a
      color       : $purple
      font-size   : 14px
      font-weight : 600
      line-height : 17px

      .material-icons
        font-size : 20px

  .full-modal-body
    border-bottom : 1px solid $light-gray
    bottom        : 0
    left          : 0
    overflow      : scroll
    position      : absolute
    right         : 0
    top           : 72px
    width         : 100%

    .container,
    .nav-container
      +container

      &:after
        content : none
