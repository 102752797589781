.question-bank
  padding-bottom : 72px

  .heading
    padding: 40px 0 0 0 !important
    min-height: 120px

    .back-to-question-types
      display : flex
      flex-direction: row
      align-content: baseline
      justify-content: flex-start

      a
        color: $medium-gray
        font-weight: 300
        text-decoration: underline

    .category-switcher
      display: flex
      align-content: baseline
      justify-content: center
      align-items: center
      margin-top: 15px

      i
        color: $medium-gray
        cursor: default

      > div
        height: 60px
        border: solid 1px $light-gray

      .arrow-link
        flex-grow: 0
        padding: 16px

        &:hover
          background: $soft-white

        &:first-child
          border-radius: 5px 0 0 5px
          border-right: none

        &:last-child
          border-radius: 0 5px 5px 0
          border-left: none

      .category-select
        flex-grow: 1
        text-align: center
        display: flex
        align-content: baseline
        justify-content: center
        align-items: center
        position: relative
        color: $medium-gray
        font-weight: 500

        &:hover
          background: $soft-white

        .icon
          margin-top: 8px

        i
          color: $aqua
          cursor: default

        .category-options
          position: absolute
          background: white
          width: 100%
          top: 100%
          border: solid 1px $light-gray
          box-shadow: 0 5px 18px 0 rgba(black, 0.12)
          max-height: 500px
          overflow-y: auto
          text-align: left

          li
            width: 100%
            padding: 15px 20px

            &:hover
              background: $soft-white


    .stages-tabs
      margin: 15px 0 15px 0
      display: flex
      flex-direction: row
      justify-content: space-around
      align-content: baseline

      li
        padding: 10px

        &.active
          color: $aqua
          border-bottom: solid 3px $aqua

    > span
      color       : $gray
      display     : block
      font-size   : 16px
      line-height : 25px
      margin      : 10px 0 -25px 0
      opacity     : 0.5


.suggest-a-question
  align-items     : center
  background      : white
  bottom          : 0
  cursor          : pointer
  color           : $green
  display         : flex
  font-weight     : bold
  height          : $header-height
  justify-content : center
  line-height     : 19px
  position        : fixed
  right           : 0
  text-decoration : underline
  width           : 29%

  &:before
    background : linear-gradient(180deg, rgba(black, 0) 0%, rgba(black, 0.5) 100%)
    content    : ''
    height     : 16px
    left       : 0
    opacity    : 0.1
    position   : absolute
    right      : 0
    top        : -16px
