#project-list,
#reflection-list
  h2
    margin : 50px 0 40px

  td:first-child
    font-size : 16px

  strong
    color       : $dark-gray
    font-weight : bold
    line-height : 19px

  .due-dates
    font-size : 16px

    a
      color : $gray
      border-bottom: solid 1px lighten($gray, 30%)
      font-size: 14px

    i
      font-size: 16px
      padding-left: 10px

    li
      margin   : 10px 0
      position : relative

      &.late
        a
          color : $red
          border-bottom: solid 1px lighten($red, 20%)

