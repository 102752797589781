.coming-soon
  border-radius    : $base-border-radius
  font-weight      : 700
  text-align       : center
  margin-left      : 15px
  background-color : $yellow
  width            : 135px
  height           : 24px
  float            : left
  padding          : 4px
  color            : $gray
  text-transform   : uppercase
  font-size        : 13px
  letter-spacing   : 1px
  line-height      : 16px
  text-align       : center

