#select-student
  .search
    position   : relative
    min-width  : 300px

    .material-icons
      color     : $slate
      font-size : 20px
      left      : 16px
      position  : absolute
      top       : calc(50% - 10px)

    input
      color       : $slate
      font-size   : 14px
      font-weight : 600
      line-height : 17px
      margin      : 0
      padding     : 15px 15px 15px 56px

  .student-list
    +grid-column(10)
    +grid-push(1)

    .actions
      font-size   : 16px
      font-weight : 600
      line-height : 19px

      a
        align-items : center
        align-self  : flex-end
        display     : flex

      .material-icons
        font-size : 18px
        margin    : 5px
