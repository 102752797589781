.question-card
  background-color : white
  background-image : linear-gradient(180deg, white 0%, $soft-white 100%)
  border           : 1px solid #D7D7D7
  border-radius    : $base-border-radius
  box-shadow       : 0 2px 4px 0 rgba(black, 0.12)
  color            : $gray
  cursor           : pointer
  font-size        : 16px
  font-weight      : 500
  line-height      : 19px
  margin-bottom    : 12px
  padding          : 16px

  span.learner-type
    display: block
    width: 100%
    text-align: right
    text-transform: uppercase
    margin: 5px 0 -5px
    font-size: 12px

  &.assignment
    border-left-width: 5px
    display          : flex
    flex-direction   : row
    margin-bottom    : 0
    position         : relative
    transition       : all 0.15s

    &:hover
      border-radius : 0
      border-right  : 0
      margin        : 0 -24px
      padding       : 20px 40px

      &:before
        content             : ''
        background-image    : radial-gradient(circle at center, $purple 0, $purple 1.5px, transparent 1.5px) , radial-gradient(circle at center, $purple 0, $purple 1.5px, transparent 1.5px), radial-gradient(circle at center, $purple 0, $purple 1.5px, transparent 1.5px), radial-gradient(circle at center, $purple 0, $purple 1.5px, transparent 1.5px), radial-gradient(circle at center, $purple 0, $purple 1.5px, transparent 1.5px), radial-gradient(circle at center, $purple 0, $purple 1.5px, transparent 1.5px)
        background-position : -2px -5px, -2px 0, -2px 5px, 2px -5px, 2px 0, 2px 5px
        background-repeat   : no-repeat
        height              : 14px
        left                : 13px
        position            : absolute
        top                 : calc(50% - 7px)
        width               : 8px

    .question
      flex : 3

    .actions
      flex        : 1
      text-align  : right

      a
        color       : $light-purple
        font-size   : 14px
        font-weight : 600
        line-height : 17px

        &:hover span
          text-decoration : underline

        &:last-child
          opacity     : 0.4

        & ~ a:before
          content     : ' | '
          font-weight : normal
          opacity     : 0.4

.meta-reflection .question-card.assignment:hover
  border           : 1px solid #D7D7D7
  border-radius    : $base-border-radius
  margin           : 0
  margin-bottom    : 12px
  padding          : 16px

  &:before
    display : none
