#notifications-list
  display : flex
  height  : 100%

  .notification-list
    background    : white
    border-radius : $base-border-radius
    box-shadow    : 0 5px 18px 0 rgba(black, 0.12)
    max-height    : 368px
    min-height    : 100px
    opacity       : 0
    overflow-y    : scroll
    padding       : 24px 19px 0
    position      : absolute
    right         : 0
    top           : 100%
    transition    : all 0.3s
    visibility    : hidden
    width         : 367px
    z-index       : 100

    &.active
      opacity    : 1
      visibility : visible

  h2
    color       : $dark-gray
    font-size   : 18px
    font-weight : bold
    line-height : 22px

  .notification
    border-top    : 1px solid #CCC
    margin-bottom : 9px
    padding-top   : 7px

    .message
      color       : $gray
      font-size   : 14px
      font-weight : 500
      line-height : 20px

      a
        display     : inline
        font-weight : bold
        margin      : 0

    .created-at
      color         : $medium-gray
      font-size     : 12px
      font-weight   : 500
      line-height   : 14px
      margin-bottom : 4px
