// Typography
$base-font-family: 'proxima-soft', $font-stack-system
$heading-font-family: $base-font-family

// Line height
$base-line-height: 1.5
$heading-line-height: 1.2

// Other Sizes
$base-border-radius: 4px
$base-spacing: 1.5em
$medium-spacing: 1.1em
$small-spacing: calc($base-spacing / 2)
$base-z-index: 0
$header-height: 72px

// Colors
$red: #ED3F3D
$yellow: #FFC969
$blue: #1565C0
$green: #90C631
$teal: #5BC4BE
$aqua: #1DAEA6
$purple: #311039
$light-purple: #593861
$dark-gray: #3A3D48
$gray: #51545E
$slate: #5A6175
$medium-gray: #6D707A
$light-gray: #E0E2E8
$soft-white: #F9F9F9

// Colors by Use
$error-color: $red
$success-color: $teal
$accessible-color: #919191
$button-color: $aqua

// Font Colors
$base-font-color: $dark-gray
$action-color: $teal

// Border
$base-border-color: $light-gray
$base-border: 1px solid $base-border-color

// Background Colors
$base-background-color: white
$secondary-background-color: tint($base-border-color, 75%)

// Focus
$focus-outline-color: $accessible-color
$focus-outline-width: 2px
$focus-outline: $focus-outline-width dotted $focus-outline-color
$focus-outline-offset: 4px

// Animations
$base-duration: 150ms
$base-timing: ease
