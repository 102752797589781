#project-header,
.full-modal-header
  align-items    : center
  background     : $purple
  color          : white
  display        : flex
  flex-direction : row
  height         : $header-height
  left           : 0
  position       : fixed
  right          : 0
  top            : 0
  width          : 100%
  z-index        : 1

  a.logo
    align-items : center
    background  : $light-purple
    display     : flex
    height      : 100%
    padding     : 0 35px

    img
      height : 29px
      width  : 80px

  h2
    margin : 0 0 0 16px

  nav
    margin-left : auto

    a
      align-items  : center
      color        : white
      display      : flex
      font-size    : 14px
      line-height  : 17px
      margin-right : 16px

@media print
  #project-header,
  .full-modal-header,
  #project-header *,
  .full-modal-header *
    display    : none
    visibility : hidden

  .full-modal
    .full-modal-body
      top : 0
