.phx-no-feedback.invalid-feedback,
.phx-no-feedback .invalid-feedback
  display: none

.phx-click-loading
  opacity: 0.5
  transition: opacity 1s ease-out

.phx-disconnected
  cursor: wait

  & *
    pointer-events: none

.phx-modal
  opacity: 1 !important
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%
  overflow: auto
  background-color: rgb(0, 0, 0)
  background-color: rgba(0, 0, 0, 0.4)

  &-content
    background-color: #fefefe
    margin: 5vh auto
    padding: 60px
    border: 1px solid #888
    width: 95%

  &-close
    color: #aaa
    float: right
    font-size: 28px
    font-weight: bold

.phx-modal-close:hover,
.phx-modal-close:focus
  color: black
  text-decoration: none
  cursor: pointer
