.live-question
  border           : 1px solid #E5E5E5
  border-radius    : 4px
  border-left-width: 5px
  background-color : #F4F4F4
  color            : $gray
  margin-bottom    : 16px
  padding          : 10px 16px

  &:first-of-type
    margin-top : 24px

  &.meta-reflection
    border           : 1px solid rgba(111,203,221,0.4)
    background-color : rgba(111,203,221,0.05)

    h4
      color         : #73CBDC
      font-size     : 16px
      line-height   : 19px
      margin-bottom : 8px

  .row
    align-items     : center
    display         : flex
    justify-content : space-between

    &:not(:first-child)
      margin-top : 10px

  .question-main
    width: 85%
    float: left

    .row
      justify-content: flex-start

  .question-info
    width: 15%
    float: right
    align-self: baseline

  .question-text
    font-size     : 16px
    font-weight   : 500
    line-height   : 19px
    padding-right : 20px

  .due-at, .responses-link
    display: block
    float: right
    font-size      : 14px
    font-weight    : normal
    line-height    : 14px
    text-align     : right
    width          : 150px

    a
      border-bottom   : solid 0.5px $gray
      color           : $light-purple
      cursor          : pointer
      font-weight     : bold

  .due-at
    display         : flex
    justify-content : flex-end
    font-size       : 13px

    .edit-container
      flex-basis    : 50px
      margin-right  : 7px

      a.edit-link
        border-bottom   : 0

    .due-at-text
      flex-basis     : 66px

  .responses-link
    padding-bottom : 12px

  .due-at
    width: 300px

  .responses-count
    color        : $medium-gray
    font-size    : 11px
    font-weight  : 600
    line-height  : 13px
    margin-right : 10px

  .progress-bar
    background      : url(progress-bar-background.png) left center repeat-x
    background-size : 6px 100%
    border-radius   : 100px
    border          : 1px solid $light-gray
    flex            : 1
    flex-grow       : 0.7
    height          : 6px
    position        : relative
    overflow        : hidden

  .progress
    right: 0
    top: 0
    background    : white
    height        : 100%
    position      : absolute
