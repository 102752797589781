
@keyframes shadow-pulse
  0%
    box-shadow: 0 0 0 0px rgba(91, 196, 190, 0.9)
  100%
    box-shadow: 0 0 0 35px rgba(91, 196, 190, 0)
#header
  +container

  .container
    +grid-column(10)
    +grid-push(2)
    align-items   : center
    border-bottom : 1px solid $light-gray
    display       : flex
    height        : 68px
    position      : relative

    > nav
      align-items     : center
      display         : flex
      height          : 100%
      justify-content : flex-end
      width           : 100%

      a
        align-items : center
        color       : $dark-gray
        display     : flex
        font-size   : 14px
        font-weight : 600
        height      : 100%
        margin-left : 10px

        &.back-button
          color        : $medium-gray
          margin       : 0
          margin-right : auto

          .material-icons
            font-size : 16px

  .notifications-trigger
    padding : 0 30px
    cursor : default

    .notification-icon
      height   : 20px
      position : relative
      cursor   : pointer

    .material-icons
      font-size : 20px

  .badge
    background    : $teal
    border-radius : 100%
    height        : 13px
    opacity       : 0
    position      : relative
    right         : -12px
    top           : -26px
    transition    : all 0.3s
    visibility    : hidden
    width         : 13px
    animation     : shadow-pulse 1s infinite

    &.active
      opacity       : 1
      visibility    : visible

  .avatar
    border-radius : 100%
    margin-right  : 8px
