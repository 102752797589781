#bank-list

  table
    tbody
      tr
        .draggable
          margin  : 0 10px 0 0
          color   : $soft-white
        &:hover
          i
            color : $teal

