.ReactModal__Body--open
  overflow: hidden

.overlay
  align-items: center
  background-color: rgba($dark-gray, 0.3)
  bottom: 0
  display: flex
  justify-content: center
  left: 0
  opacity: 0
  overflow: scroll
  padding: 40px
  position: fixed
  right: 0
  top: 0
  transition: all 0.3s
  visiblity: hidden
  z-index: 1

  &.no-shadow
    background-color: transparent

.modal
  background-color: white
  border-radius: $base-border-radius
  max-width: 560px
  opacity: 0
  padding: 64px
  position: relative
  text-align: center
  transition: all 0.6s
  width: 100%
  visiblity: hidden

  &:focus
    outline: none

  &.widest
    max-height: 100%
    overflow: scroll
    max-width: 80%

  &.wide
    max-width: 75%

    textarea
      height: 250px

  .close
    color: $slate
    font-size: 14px
    font-weight: 600
    line-height: 17px
    cursor: pointer
    position: absolute
    right: 32px
    top: 24px

  .close-button
    margin: 18px
    width: calc(100% - 36px)

  form
    padding: 18px

  textarea
    border: 1px solid $light-gray
    color: $gray
    font-weight: bold
    line-height: 19px
    min-height: 148px
    padding: 16px
    resize: none

    &::placeholder
      color: $gray
      font-weight: bold
      line-height: 19px

  button
    width: 100%

  .actions
    display: flex

    button:first-child
      background: $medium-gray
    button:only-child
      background: $aqua

    button + button
      margin-left: 20px

      &:hover
        background: darken($medium-gray, 5)

  .list-container
    margin: 0 13px
    text-align: left

  ul
    list-style-type: disc
    margin: 10px 35px

.overlay-after-open,
.modal-after-open
  opacity: 1
  visibility: visible

.modal-container
  display: none
  z-index: 500
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

  .top-bar
    background-color: rgba(250, 250, 250, 0.4) /* You might need to adjust this based on the exact shade of zinc-50 */
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    transition: opacity 0.1s ease-in-out
  .inner-one
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    overflow-y: auto
    .inner-two
      display: flex
      min-height: 100%
      align-items: center
      justify-content: center
      .inner-three
        width: 100%
        max-width: 768px /* 3xl size */
        padding: 1rem
        .focus-wrap
          position: relative
          display: none
          border-radius: 1rem /* 2xl size */
          background-color: white
          padding: 3.5rem /* 14 size */
          box-shadow: 0 10px 15px -3px rgba(39, 39, 42, 0.1), 0 4px 6px -2px rgba(39, 39, 42, 0.05) /* lg shadow */
          border: 1px solid rgba(39, 39, 42, 0.1) /* ring-1 and ring color */
          transition: all 0.1s ease-in-out /* default transition */
          .cancel-container
            position: absolute
            top: 1.5rem /* 6 size */
            right: 1.25rem /* 5 size */
            .cancel-button
              margin: -0.75rem /* negative margin */
              flex: none /* non-flexible element */
              padding: 0.05rem /* padding */
              opacity: 0.8 /* initial opacity */
              transition: opacity 0.1s ease-in-out
              .size-6
                width: 2rem
                height: 2rem
