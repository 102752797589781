$border-gray: #D7D7D7

.time-option
  padding: 5px 10px
  &:hover
    background-color: #4e9bff
    color: #FFF

.time-picker
  background: $soft-white
  border: 1px solid $border-gray
  border-radius: $base-border-radius
  box-shadow: 0 5px 18px 0 rgba(black, 0.12)
  opacity: 0
  padding: 20px 10px
  position: absolute
  transition: all 0.3s
  visibility: hidden
  z-index: 10000
  max-width: 300px

  &.active
    opacity: 1
    visibility: visible

  &:before
    +triangle('up', 20px, 10px, $border-gray)
    content: ''
    position: absolute
    top: -10px
    left: calc(50% - 6px)

  &:after
    +triangle('up', 18px, 9px, $soft-white)
    content: ''
    position: absolute
    top: -9px
    left: calc(50% - 5px)

  .timepicker
    align-items: center
    border-top: 1px solid $border-gray
    border-bottom: 1px solid $border-gray
    display: flex
    flex-direction: row
    justify-content: center
    margin: 12px 0
    padding: 10px 0

    input
      font-weight: bold
      margin: 0
      max-width: 52px
      padding-left: 0
      padding-right: 0
      text-align: center

      &:last-of-type
        margin-right: 5px

    input[type=number]::-webkit-inner-spin-button
      -webkit-appearance: none

    span
      font-weight: bold
      margin-left: 10px

      &:first-of-type
        margin-right: 10px

      &.meridiem
        cursor: pointer
        opacity: 0.3

        &.active
          opacity: 1

  .set-date, .set-date-only
    font-weight: bold
    width: 100%

  .set-date-only
    margin-top: 15px
