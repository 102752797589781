.spinner
  height   : 20px
  position : relative
  width    : 20px

  > div
    animation     : spinner 1.5s cubic-bezier(0.5,0,0.5,1) infinite
    border        : 2px solid $green
    border-color  : $green transparent transparent transparent
    border-radius : 50%
    height        : 80%
    left          : 10%
    position      : absolute
    top           : 10%
    width         : 80%

  > div:nth-child(2)
    animation-delay : .195s

  > div:nth-child(3)
    animation-delay : .39s

  > div:nth-child(4)
    animation-delay : .585s

@keyframes spinner
  0%
    transform : rotate(0)

  100%
    transform : rotate(360deg)

.uploading
  display: none
  padding-top: 20px
  .spinner
    display: inline
    margin: 20px