$fancy-border : 1.5px solid $light-gray

table.fancy
  border-collapse : initial
  border-spacing  : 0
  table-layout    : auto

  th
    font-size   : 12px
    font-weight : 500
    padding     : 0 0 16px

    > div
      border-bottom : 1px solid $light-gray
      padding       : 15px 0
      padding-right : 20px
      text-align    : left

      .choices
        width: 180px

        .choices__item
          padding-right: 0

    &:first-child > div
      padding-left : 24px

    &:last-child > div
      padding-right : 24px

  td
    background : white
    padding    : 0

    &:first-child
      border-left  : $fancy-border
      max-width    : 400px
      padding-left : 24px

    &:last-child
      border-right  : $fancy-border
      padding-right : 24px

    label
      margin : 0

  tbody tr
    &.disabled td *
      color : rgba($gray, 0.5)

      label
        opacity : 0.5

    &:first-child td
      border-top  : $fancy-border
      padding-top : 8px

      &:first-child
        border-top-left-radius : $base-border-radius

      &:last-child
        border-top-right-radius : $base-border-radius

    &:last-child td
      border-bottom  : $fancy-border
      padding-bottom : 8px

      > div
        border-bottom : 0

      &:first-child
        border-bottom-left-radius : $base-border-radius

      &:last-child
        border-bottom-right-radius : $base-border-radius

    &:not(.disabled):hover
      border-radius : $base-border-radius
      box-shadow    : 0 5px 18px 0 rgba(black, 0.12)
      transition    : all 0.3s

      td
        border-color : white
        position     : relative
        transition   : all 0.3s

        &:before
          background : white
          box-shadow : 0 -5px 18px 0 rgba(black, 0.4)
          content    : ''
          height     : 1px
          left       : 0
          position   : absolute
          top        : -1px
          width      : 100%

        &:after
          background : $light-gray
          bottom     : 0
          box-shadow : 0 5px 18px 0 rgba(black, 0.4)
          content    : ''
          height     : 1px
          left       : 0
          position   : absolute
          bottom     : -1px
          width      : 100%

        > div
          border-color : white

        &:first-child
          border-bottom-left-radius : $base-border-radius
          border-top-left-radius    : $base-border-radius

        &:last-child
          border-bottom-right-radius : $base-border-radius
          border-top-right-radius    : $base-border-radius

  tbody td
    color       : $gray
    font-size   : 14px
    font-weight : 500
    height      : 1px
    line-height : 25px
    max-width   : 30%

    > div
      align-items     : flex-start
      border-bottom   : 1px solid $light-gray
      display         : flex
      flex-direction  : column
      height          : auto
      justify-content : center
      min-height      : 100%
      padding         : 16px 0
      padding-right   : 20px
      &.center
        align-items   : center

    &:last-child > div
      padding-right : 0

  .badge
    background-color : $green
    border-radius    : 100px
    color            : white
    display          : inline-block
    font-size        : 12px
    font-weight      : bold
    height           : 16px
    line-height      : 14px
    line-height      : 16px
    margin           : 8px
    text-align       : center
    width            : 24px

.remove-top-margin
  margin-top: -120px
