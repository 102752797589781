$_button-background-color: $button-color
$_button-background-color-hover: shade($button-color, 20%)

a.button, a.delete-button,
#{$all-buttons}
  appearance: none
  background-color: $_button-background-color
  border: 0
  border-radius: $base-border-radius
  color: white
  cursor: pointer
  display: inline-block
  font-family: $base-font-family
  font-size: 14px
  -webkit-font-smoothing: antialiased
  font-weight: 600
  letter-spacing: 2px
  line-height: 1
  padding: $medium-spacing $base-spacing
  text-align: center
  text-decoration: none
  text-transform: uppercase
  transition: background-color $base-duration $base-timing
  user-select: none
  vertical-align: middle
  white-space: nowrap

  &:hover
    background-color: $_button-background-color-hover
    color: white

  &:focus
    outline: $focus-outline
    outline-offset: $focus-outline-offset

    &.focus--mouse
      outline: 0

  &:disabled, &.disabled
    background: #CFCFCF
    cursor: not-allowed

    &:hover
      background-color: darken(#CFCFCF, 5)

a.delete-button
  background-color: $red

  &:hover
    background-color: shade($red, 20%)
    color: white

.question-category-toggle
  font-size: 10px
  padding: 1em
  &.hide
    background-color: shade($red, 20%)
  &.inactive
    background-color: shade($yellow, 20%)
