html
  box-sizing : border-box

*,
*::before,
*::after
  box-sizing : inherit

html,
body
  height : 100%

body
  margin     : 0
  background : $soft-white

@mixin container
  +grid-container
  margin    : 0 auto
  max-width : 86.8055556%

  // TODO: mobile responsive
