.dropdown
  align-items     : flex-start
  background      : white
  border-radius   : $base-border-radius
  box-shadow      : 0 5px 18px 0 rgba(black,0.12)
  display         : flex
  flex-direction  : column
  justify-content : flex-start
  opacity         : 0
  padding         : 24px 16px 24px 19px
  position        : absolute
  right           : 2px
  top             : 100%
  transition      : all 0.3s
  visibility      : hidden
  width           : 210px
  z-index         : 1

  &.active
    opacity    : 1
    visibility : visible

  .user-name
    font-size   : 18px
    font-weight : bold
    padding     : 0 0 17px

  hr
    margin : 0 0 23px

  > a
    align-items : center
    display     : flex
    font-size   : 16px !important
    font-weight : 600
    height      : 40px !important
    margin-left : 0 !important
    width       : 100%
