#sidebar
  align-items: center
  background: $purple
  bottom: 0
  display: flex
  flex-direction: column
  left: 0
  position: fixed
  top: 0
  width: 230px
  z-index: 1

  .logo
    margin-top: 24px
    margin-bottom: 45px
    width: 120px

  nav
    width: 100%
    div.break
      width: 90%
      margin: auto
      padding: 10px 0
      hr
        margin: 0

  nav a
    align-items: center
    color: white
    display: flex
    height: 50px
    opacity: 0.4
    padding: 0 16px
    transition: all 0.3s
    width: 100%

    &.active
      background: rgba(white, 0.12)
      opacity: 1

    &:hover
      opacity: 1

    .material-icons
      font-size: 16px
      margin-right: 8px

    .badge
      align-items: center
      background: $green
      border-radius: 100px
      display: flex
      font-weight: bold
      height: 24px
      justify-content: center
      margin-left: auto
      width: 24px

#outstanding-count
  margin-left: auto
