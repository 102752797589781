.search-autocomplete
  margin-left: auto
  margin-top: -15px

  input
    color       : $slate
    font-size   : 14px
    font-weight : 600
    line-height : 15px
    margin      : 0
    padding     : 12px

  > div > div
    padding: 5px 15px
