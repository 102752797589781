.responses-header
  border-bottom : 1px solid $light-gray

  .container
    display         : flex
    flex-direction  : row
    justify-content : space-between

    &.top-pad
      padding-top : 48px

  .nav-container
    align-items   : center
    border-bottom : 1px solid $light-gray
    display       : flex
    height        : 68px
    position      : relative

    > nav
      align-items     : center
      display         : flex
      height          : 100%
      justify-content : flex-end
      width           : 100%

      a
        align-items : center
        color       : $dark-gray
        display     : flex
        font-size   : 16px
        font-weight : 600
        height      : 100%
        margin-left : 10px

        &.back-button
          color        : $medium-gray
          margin       : 0
          margin-right : auto

          .material-icons
            font-size : 16px

  .info
    flex           : 0 1 auto
    padding-bottom : 40px
    padding-right  : 50px

  button
    flex        : 0 1 auto
    font-weight : bold
    max-height  : 45px
    min-width   : 255px

  &:not(.pulse) button
    min-width : 225px

  &.anonymous button
    background : transparent
    border     : 1.5px solid $aqua
    color      : $aqua
    min-width  : 160px

    &:hover
      background : $aqua
      color      : white

  h1.title
    color         : $dark-gray
    font-size     : 32px
    font-weight   : 500
    line-height   : 38px
    margin-bottom : 7px

  .course-info
    color       : $medium-gray
    font-size   : 16px
    font-weight : 500
    line-height : 19px

  .due-at
    color       : $gray
    font-weight : 700
    line-height : 19px

  .actions
    align-items : flex-start
    display     : flex

  .print
    align-items  : center
    color        : $dark-gray
    display      : flex
    font-size    : 16px
    font-weight  : 500
    height       : 45px
    line-height  : 19px
    margin-right : 30px

    .material-icons
      font-size    : 18px
      margin-right : 7px

  .controls
    align-items     : center
    display         : flex
    flex            : 1
    justify-content : space-between

  .section-select
    display : flex

    a
      cursor      : pointer
      display     : block
      font-weight : 600
      margin-left : 10px
      padding     : 10px

      &:first-child
        margin-left: 0

      &:not(.selected)
        color   : $gray
        opacity : 0.5

        &:hover
          border-bottom : 2px solid $gray

      &.selected
        border-bottom : 2px solid $aqua

.responses-key
  +container
  align-items : center
  display     : flex
  padding     : 32px 0 24px

  h2
    color          : $dark-gray
    font-size      : 20px
    font-weight    : 600
    line-height    : 24px
    margin         : 0
    text-align     : left

  .responses-title
    width : 64%

  .notes-title
    margin-left : 4%
    width       : 32%

  .notes-subtitle
    color       : $gray
    font-size   : 14px
    font-weight : 500
    line-height : 17px

.responses.anonymous
  background : white

.response-row
  border-bottom : 1px solid $light-gray
  padding       : 24px 0

  &.reflection
    padding : 72px 0

  .container
    +container
    display : flex

.response
  text-align : left

  &.pulse
    width : 64%

    .student-name
      cursor          : pointer
      text-decoration : underline

  .student-name
    color       : $dark-gray
    font-size   : 16px
    font-weight : 700
    line-height : 24px

  .submitted-at
    align-items   : center
    color         : $medium-gray
    display       : flex
    font-size     : 12px
    font-weight   : 700
    line-height   : 26px
    margin-bottom : 25px

    .material-icons
      font-size      : 16px
      margin-right   : 4px

    .badge
      align-items     : center
      background      : $red
      border-radius   : 100px
      color           : white
      display         : flex
      height          : 16px
      justify-content : center
      margin-right    : 8px
      width           : 40px

  .text
    background-image : linear-gradient(transparent 34px, $light-gray 34px, $light-gray 35px, white 35px)
    background-size  : 100% 35px
    border           : 0
    border-radius    : 0
    color            : $gray
    font-size        : 16px
    line-height      : 35px
    margin           : 24px 0 10px
    padding          : 0

  .evidence
    align-items : center
    color       : #6A6D77
    display     : flex
    font-size   : 12px
    font-weight : 600
    line-height : 14px
    margin-top  : 24px

    .material-icons
      font-size    : 20px
      margin-right : 5px
      transform    : rotate(30deg)

.response-note
  border        : 1.5px solid $light-gray
  border-radius : $base-border-radius
  box-shadow    : 0px 4px 8px 0px rgba(black, 0.08)
  color         : $gray
  overflow      : hidden
  text-align    : left

  &.pulse
    margin-left : 4%
    width       : 32%

  .notes
    color            : $gray
    background-color : white
    font-size        : 16px
    font-weight      : 500
    line-height      : 35px
    min-height       : 129px
    padding          : 14px 16px

    &.collaborator
      background-color : #FEFFF9
      border-top       : 1px solid $light-gray
      color            : rgba($gray, 0.5)
      font-style       : italic

      textarea
        background-color : #FEFFF9

    .title
      color         : $gray
      display       : block
      font-size     : 12px
      font-style    : normal
      font-weight   : bold
      line-height   : 1
      margin-bottom : 8px
      text-align    : right

    textarea
      border        : 0
      border-radius : 0
      box-shadow    : none
      color         : $gray
      font-size     : 16px
      line-height   : 35px
      margin        : 0
      padding       : 0

      &::placeholder
        color       : rgba($gray, 0.5)
        font-weight : 500
        line-height : 35px

@media print
  .responses-header
    padding-top : 0

    .actions
      display    : none
      visibility : hidden

  #reflection
    .submitted-at
      .badge
        display    : none