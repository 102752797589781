#project-sidebar
  background : white
  bottom     : 0
  box-shadow : 0 5px 18px 0 rgba(black, 0.14)
  overflow-y : scroll
  padding    : 0 16px
  position   : fixed
  right      : 0
  top        : $header-height
  width      : 29%

  .heading
    border-bottom : 1px solid $light-gray
    margin        : 0 8px
    padding       : 40px 0

    h1
      color       : #302433
      font-size   : 26px
      font-weight : 500
      line-height : 31px
      margin      : 0
