.well
  background    : white
  border        : 1.5px solid $light-gray
  border-radius : $base-border-radius
  margin-bottom : 56px
  min-width     : 635px
  padding       : 32px 52px 56px

  @media only screen and (max-width: 655px)
    min-width : initial
    width     : 90vw

  .title
    font-size   : 20px
    font-weight : 500
    line-height : 24px
    text-align  : center

  .options
    margin-bottom   : 44px
    align-items     : center
    display         : flex
    justify-content : space-between

  .cancel
    margin-top : 20px
    text-align : center

  .button
    width : 100%
