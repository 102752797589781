.org-options
  +grid-container
  .org-option-column-5
    +grid-column(5)
  .org-option-column-2
    +grid-column(2)
  .org-option-column-3
    +grid-column(3)

div.well.edit-organization
  display: none

div.well.edit-organization.active
    display: block

#organization-users
  display: none

#organization-users.active
    display: block