$card-shadow: 0 2px 4px 0 rgba(black, 0.12), 0 0 1px 0 rgba(black, 0.12)

.group-card
  +grid-column(1 of 3)
  background-color: white
  border-radius: $base-border-radius
  box-shadow: $card-shadow
  height: 325px
  min-width: 350px
  margin-bottom: 24px
  position: relative

  &.new
    align-items: center
    background-color: $light-gray
    color: $slate
    cursor: pointer
    display: flex
    flex-direction: column
    font-size: 14px
    font-weight: 600
    justify-content: center
    line-height: 17px

    > .material-icons
      color: $gray
      font-size: 48px
      line-height: 50px
      margin-bottom: 16px
      opacity: 0.4

  &.form
    left: 0
    margin: 0
    opacity: 0
    padding: 24px
    position: absolute
    top: 0
    transition: all 0.3s
    visibility: hidden
    width: 100%
    z-index: -1

    &.active
      opacity: 1
      visibility: visible
      z-index: 1

  .field.floating
    margin: 34px 0 24px

  .group-form-header
    align-items: center
    border-bottom: 1px solid #CCC
    display: flex
    font-size: 18px
    font-weight: bold
    justify-content: space-between
    line-height: 22px
    padding-bottom: 17px

    .dismiss
      cursor: pointer

  .group-header
    align-items: center
    color: white
    display: flex
    border-radius: $base-border-radius $base-border-radius 0 0
    font-weight: bold
    height: 67px
    justify-content: space-between
    line-height: 19px
    padding: 0 24px
    position: relative

    .dropdown-toggle
      cursor: pointer

    .material-icons
      color: white
      font-size: 24px
      line-height: 24px

    .dropdown
      cursor: pointer
      padding: 32px 32px 16px 16px
      right: 7px
      top: 4px
      width: auto

      .dismiss
        position: absolute
        right: 16px
        top: 16px

      a
        color: $slate

      .material-icons
        color: $dark-gray

  .group-details
    border-radius: 0 0 $base-border-radius $base-border-radius
    height: calc(100% - 67px)
    padding-bottom: 70px
    position: relative

  .group-sections
    color: $gray
    font-weight: 500
    height: 100%
    overflow: scroll
    padding: 24px 12px 0 24px

    .section
      align-items: center
      display: flex
      flex-direction: row
      justify-content: space-between
      line-height: 1
      padding: 10px 0

      .section-name
        width: 40%
      .section-code
        text-align: center
        width: 18%
        display: inline

    .section-actions
      align-items: center
      display: flex

    .section-edit,
    .section-delete
      color: $gray
      cursor: pointer

    .section-edit
      margin-right: 12px
      text-decoration: underline

    .section-delete
      height: 16px

      i.material-icons
        font-size: 16px

  .section-new
    background: white
    border: 1px solid #DADADA
    bottom: 24px
    color: $gray
    font-size: 13px
    font-weight: bold
    letter-spacing: 1px
    left: 24px
    line-height: 16px
    margin-top: auto
    padding: 0.54em 1.5em
    position: absolute
    text-align: center

  .group-save
    font-weight: bold
    margin-top: 32px
    width: 100%
