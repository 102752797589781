.calendar
  .toolbar
    align-items     : center
    display         : flex
    justify-content : space-between
    margin-bottom   : 0

    .current-date
      font-size : 20px

    button
      align-items     : center
      background      : transparent
      border          : 1px solid $purple
      border-radius   : 50%
      color           : $purple
      display         : flex
      height          : 26px
      justify-content : center
      padding         : 0
      width           : 26px

  table
    margin     : 0
    text-align : center

    tr
      border : 0

    th, td
      height         : 30px
      width          : 30px
      padding        : 0
      text-align     : center
      user-select    : none
      vertical-align : middle

    th
      color : $purple

    td
      color  : $gray
      cursor : pointer

      &.disallowed
        cursor : default

      &.prev-month,
      &.next-month
        color: lighten($gray, 25%)

      &.disallowed
        color : $light-gray

      &.current-day
        background    : $green
        border-radius : 50%
        color         : white

