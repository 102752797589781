.warning
  background-color : #F4F4F4
  border           : 1.5px solid #E5E5E5
  display          : flex
  margin-bottom    : 40px
  padding          : 16px
  width            : 100%

  .material-icons
    color        : $gray
    font-size    : 20px
    margin-right : 13px

  span
    color       : $gray
    font-size   : 12px
    font-weight : 500
    line-height : 18px
