body
  &.session-new,
  &.session-create,
  &.password-new,
  &.reset_password-new,
  &.reset_password-edit,
  &.password-create,
  &.password-edit,
  &.password-update,
  &.unlock-new
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .logo
      margin-bottom: 56px
      width: 250px
