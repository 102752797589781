.section-picker
  h2
    margin-bottom : 8px

  .section-option
    padding       : 24px 0
    border-bottom : 1px solid $light-gray

    label
      margin-bottom  : 0
      text-align     : left

      small
        font-size   : 12px
        font-weight : 500
        line-height : 14px

  .add-new
    align-items : center
    cursor      : pointer
    display     : flex
    padding     : 32px 0

    .material-icons
      margin-right : 8px

  button
    margin-top : 18px
    width      : 258px
