#project-list
  user-select : none

  .section-names
    max-width : 300px

    p
      margin : 0

    > div
      align-items     : center
      flex-flow       : row wrap
      justify-content : flex-start
      line-height     : 24px
      padding-right   : 20px

  .share .material-icons
    transform : scaleX(-1)

  .actions
    > div
      align-items : flex-end

  .more-trigger
    display  : flex
    position : relative

td.project-name
  strong
    display    : block
    opacity    : 1
    visibility : visible

  .field.floating
    display    : none
    opacity    : 0
    visibility : hidden

  &.renaming
    strong
      display    : none
      opacity    : 0
      visibility : hidden

    .field.floating
      display    : block
      margin     : 0
      opacity    : 1
      visibility : visible

.project-actions
  background-color : white
  border-radius    : 4px
  box-shadow       : 0 5px 18px 0 rgba(black, 0.12)
  display          : none
  left             : -148px
  margin-bottom    : 50px
  opacity          : 0
  padding          : 12px 16px 8px 16px
  position         : absolute
  top              : -12px
  visibility       : hidden
  width            : 188px
  z-index          : 2

  &.active
    display    : block
    opacity    : 1
    visibility : visible

  hr
    margin : 0

  .actions-close-div
    height : 24px

    .actions-close-trigger
      float : right

  .action-div
    align-items  : center
    color        : $slate
    display      : flex
    font-size    : 16px
    font-weight  : 600
    line-height  : 60px
    margin-left  : 24px
    margin-right : 24px

    i
      font-size    : 20px
      margin-right : 16px

@media print
  body.project-edit
    main
      display : none

    .overlay-after-open,
    .modal-after-open
      display  : block
      padding  : 0
      position : static

      .full-modal-body
        display  : block
        position : static

