.reflection-form
  margin-bottom: 32px

  &.active .reflection-card
    box-shadow: 0 5px 18px 0 rgba(black, 0.12)

  .actions
    align-items: center
    display: flex

  button
    width: 150px

  .msg
    color: $medium-gray
    font-size: 12px
    margin-left: 15px
    opacity: 0
    transition: all 0.3s
    visibility: hidden

    &.error,
    &.saved
      opacity: 1
      visibility: visible

    &.error
      color: $red

  label
    text-align: left
    color: $dark-gray
    font-weight: bold

  .text
    background-image: linear-gradient(transparent 34px, $light-gray 34px, $light-gray 35px, white 35px)
    background-size: 100% 35px
    border: 0
    border-radius: 0
    color: $gray
    font-size: 16px
    line-height: 35px
    margin: 24px 0 20px
    padding: 0
    text-align: left

.reflection-card
  background: white
  box-shadow: 0 2px 4px 0 rgba(black, 0.12), 0 0 1px 0 rgba(black, 0.12)
  border-radius: $base-border-radius
  margin: 24px 0
  padding: 24px

  .due-at,
  .word-count
    align-items: center
    color: $medium-gray
    display: flex
    font-size: 12px
    font-weight: 500
    line-height: 2

  .due-at
    float: right
    margin-left: 70px
    margin-bottom: 1px

    &.late
      color: $red

    .material-icons
      font-size: 16px
      letter-spacing: 0
      margin-right: 5px

#reflection-share.export
  padding-top: 26px
#reflection,
#reflection-share
  .export
    font-size: 0.85em
    line-height: 1.2em

    .completed-assignments
      border: 0

    .submitted-at
      margin-left: 30px

    .group-owner
      margin-bottom: 25px

    .assignment
      margin-bottom: 25px

  .print-logo
    float: right
    margin-top: -50px
    width: 100px

  .time-to-reflect
    padding: 40px 0

  .description
    color: #5BC4BE
    text-align: left
    font-size: 1.5em
    margin-bottom: -25px

  .export-row
    display: flex
    align-items: center
    justify-content: center

    .col0
      flex: 0.05
    .col1
      text-align: left
      flex: 1

  label.export
    margin-bottom: 1.2em

  h1
    font-size: 1.8em

  h2
    background: $soft-white

    &.sticky
      padding-top: 16px

      &:before,
      &:after
        background: $soft-white
        bottom: 0
        content: ' '
        position: absolute
        top: 0
        width: 10px

      &:before
        left: -10px

      &:after
        right: -10px

  .actions
    align-items: center
    display: flex

    a
      color: $slate
      cursor: pointer
      display: flex
      font-size: 16px
      font-weight: 500
      line-height: 19px
      margin-left: 16px
      position: relative

      .material-icons
        font-size: 20px
        margin: 0 5px

      &:last-child
        > .material-icons
          transform: scaleX(-1)

  .exporting,
  .share-copied
    align-items: center
    background-color: white
    box-shadow: 0 5px 18px 0 rgba(black, 0.12)
    color: $medium-gray
    display: flex
    height: 36px
    justify-content: center
    left: -50%
    opacity: 0
    position: absolute
    text-align: right
    top: -200%
    transition: all 0.3s
    visibility: hidden
    width: 154px
    z-index: 1

    &.active
      opacity: 1
      visibility: visible

  .exporting .material-icons
    color: $medium-gray
    font-size: 20px
    margin-left: 0 !important

  .exporting,
  .share-copied
    span
      font-size: 12px
      font-weight: 500
      line-height: 14px
      margin: 0

  .exporting.done,
  .share-copied
    .material-icons
      color: $green
      font-size: 20px
      margin-left: 0 !important

  .completed-assignments
    background: white
    border: 2px solid $light-gray
    border-radius: $base-border-radius
    margin: 24px 0
    padding: 24px

    &:last-of-type
      margin-bottom: 56px

  .assignment
    margin-bottom: 47px

    &:last-child
      margin-bottom: 0

  .attachments
    display: flex

  .attach-evidence
    align-items: center
    border-radius: $base-border-radius
    border: 2px dotted $light-gray
    color: $medium-gray
    cursor: pointer
    display: flex
    font-size: 16px
    height: 45px
    justify-content: center
    line-height: 25px
    margin-bottom: 24px
    margin-right: auto
    min-width: 330px
    padding: 0 24px

    span
      font-weight: bold
      text-decoration: underline

    .material-icons
      margin-right: 16px

  .subheader
    margin-top: 20px
    a
      align-items: center
      display: flex
      i
        margin-right: 5px

  .text
    background-image: linear-gradient(transparent 34px, $light-gray 34px, $light-gray 35px, white 35px)
    background-size: 100% 35px
    border: 0
    border-radius: 0
    color: $gray
    font-size: 16px
    line-height: 35px
    margin: 24px 0 10px
    padding: 0

  .evidence
    align-items: center
    color: #6A6D77
    display: flex
    font-size: 12px
    font-weight: 600
    justify-content: flex-start
    line-height: 14px
    margin-top: 24px
    text-align: left

    img
      height: 18px
      margin-right: 5px
      width: 14px

    .material-icons
      font-size: 20px
      margin-right: 5px
      transform: rotate(30deg)

  .submitted-at
    align-items: center
    color: $medium-gray
    display: flex
    float: right
    font-size: 12px
    font-weight: 500
    line-height: 2
    margin-left: 70px
    margin-bottom: 1px

    .badge
      align-items: center
      background: $red
      border-radius: 100px
      color: white
      display: flex
      height: 16px
      justify-content: center
      line-height: 16px
      margin: 0 8px
      text-align: center
      width: 40px

  .meta-reflection
    color: #6FCBDD
    margin-bottom: 15px

  label
    color: $dark-gray
    font-weight: bold

  textarea,
  .textarea
    background-image: linear-gradient(white 34px, $light-gray 34px, $light-gray 35px, white 35px)
    background-size: 100% 35px
    border: 0
    border-radius: 0
    color: $gray
    font-size: 16px
    line-height: 35px
    margin: 24px 0 10px
    padding: 0

    &:focus
      box-shadow: none

  .random-quote
    color: $gray
    font-size: 18px
    font-weight: 500
    line-height: 35px
    margin: 56px
    text-align: center
    transition: all 0.5s

    cite
      display: block
      font-style: normal

  .save-info
    align-items: center
    display: flex

    button
      background: transparent
      border: 1px solid $dark-gray
      color: $dark-gray
      font-size: 14px
      margin-left: 18px
      padding: 0.575em 1.15em

  .save-indicator
    align-items: center
    display: flex

    span
      color: $dark-gray
      font-size: 14px
      font-weight: bold
      margin-left: 6px

@media print
  body.reflection-show,
  body.my_reflection-show
    background: white

    *
      visibility: hidden

  #reflection-show,
  #reflection-share,
  #reflection-show *,
  #reflection-share *
    visibility: visible

  #reflection-show,
  #reflection-share
    left: 0
    margin: 0 !important
    max-width: 100% !important
    position: absolute
    top: 0
    width: 100% !important

    .container
      margin: 0 !important
      max-width: 100% !important
      width: 100% !important

    .time-to-reflect,
    .actions
      *
        display: none
        visibility: hidden
  .container
    h1
      margin-top: 30px
  .print-logo
    position: absolute
    right: 70px
    top: 100px

.postscriptAdded
  color: $teal

.reflection-card.add-more
  textarea
    font-weight: normal
  textarea::placeholder
    font-weight: normal
