#reflection-share,
#reflection-show
  +container
  padding-top : 56px

  .container
    +grid-column(10)
    +grid-push(2)

    &.flex
      display         : flex
      flex-direction  : column
      justify-content : center

    &.flash .flash-message
      margin-bottom : 56px
      width         : 100%

      &.hidden
        margin : 0

  h1
    margin-bottom : 8px

  .group-owner
    color         : $medium-gray
    font-weight   : 500
    line-height   : 19px
    margin-bottom : 54px

  h2
    align-items     : center
    border-bottom   : 1px solid $light-gray
    display         : flex
    flex-direction  : row
    justify-content : space-between
    padding         : 16px 0

    .last-modified
      color     : $dark-gray
      font-size : 14px

#reflection-share
  .container
    +grid-column(8)
    +grid-push(2)

#reflection-share.integration
  max-width: 100%

  .container
    width: 100%
    float: none
    padding: 0 100px
    margin-left: auto
    margin-right: auto

  .group-owner
    margin-bottom: 20px

@media print
  #reflection-share.integration
    .container
      padding: 0 50px