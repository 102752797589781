.tabbed-subnav
  clear         : both
  float         : left
  margin-bottom : 79px
  position      : relative
  width         : 100%

  &:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 120%
    border-bottom : solid 1px $light-gray

  a
    color         : $medium-gray
    display       : inline-block
    font-size     : 14px
    font-weight   : 600
    line-height   : 17px
    padding       : 20px 15px

    &:hover
      color : lighten($medium-gray, 15)

    &.active
      border-bottom : solid 3px $aqua
      color         : $teal

  .badge-count
    background-color : $green
    border-radius    : 100px
    color            : white
    display          : inline-block
    font-size        : 12px
    font-weight      : bold
    height           : 16px
    line-height      : 16px
    margin-left      : 8px
    text-align       : center
    width            : 16px
