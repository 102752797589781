.question-search
  margin-top : 15px
  margin-bottom: -10px

.question-filters
  margin : 15px 8px 24px

  hr
    margin : 40px 0

  .categories
    color: $gray

    li
      border      : 1px solid white
      font-weight : 500
      padding     : 5px 20px
      position    : relative
      width       : 100%

      .material-icons
        position : absolute
        opacity  : 0
        right    : 12px
        top      : calc(50% - 12px)

      &.disabled
        opacity: 0.65

      &:hover:not(.disabled)
        border-color  : $light-gray
        border-radius : 4px
        cursor        : pointer

        .material-icons
          opacity: 1

  .stages
    align-items     : center
    display         : flex
    flex-direction  : row
    justify-content : space-between

  .stage
    color        : $slate
    cursor       : pointer
    font-size    : 16px
    font-weight  : 500
    line-height  : 25px

    &.active
      border-bottom : 2px solid $teal
      color         : $teal
      font-weight   : 600
