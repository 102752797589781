.avatar
  border-radius : 100%

  &.small
    height : 32px
    width  : 32px

  &.large
    height : 96px
    width  : 96px

.avatar-input
  cursor     : pointer
  text-align : center

  input
    height   : 0px
    overflow : hidden

    &:focus
      outline : none
