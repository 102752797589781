.color-select
  position : relative
  height   : 48px

  .trigger
    align-items      : center
    background-color : white
    border           : 1px solid $light-gray
    border-radius    : $base-border-radius
    cursor           : pointer
    display          : flex
    flex-direction   : row

    > *
      margin : 8px

  .swatch
    background-color : $slate
    border-radius    : $base-border-radius
    cursor           : pointer
    height           : 32px
    width            : 32px

  .material-icons
    margin-left : auto

  input
    filter   : alpha(opacity=0)
    left     : 0
    opacity  : 0
    position : absolute
    top      : 0
    z-index  : -1

  .color-options
    background-color : white
    box-shadow       : 0 5px 18px 0 rgba(black, 0.12)
    display          : flex
    flex-flow        : row wrap
    opacity          : 0
    padding          : 13px
    position         : relative
    transition       : all 0.3s
    visibility       : hidden

    &.active
      opacity    : 1
      visibility : visible

    .swatch
      margin : 4.5px
