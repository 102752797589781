body.page-index
  background : $purple

#coming-soon
  +container
  align-items : center
  display     : flex
  height      : 100vh

  .container
    +grid-column(8)
    +grid-push(2)
    align-items     : center
    align-self      : center
    display         : flex
    flex-flow       : column
    justify-content : center
    text-align      : center

  a.login
    color    : white
    position : absolute
    right    : 50px
    top      : 30px

    &:hover
      text-decoration : underline

  .logo
    margin : 24px 0
    width  : 296px

  h1
    color     : $aqua
    font-size : 48px
    margin    : 0

  p
    color     : white
    font-size : 24px
    margin    : 48px 0

  .button
    padding-left  : 50px
    padding-right : 50px
