body
  &.onboarding-new
    &, main
      display         : flex
      flex-direction  : column
      align-items     : center
      justify-content : center

    .logo
      margin-bottom : 56px
      width         : 250px

    .well
      background    : white
      border        : 1.5px solid $light-gray
      border-radius : $base-border-radius
      margin-bottom : 56px
      min-width     : 835px
      padding       : 32px 52px 56px

      .top-p
        margin-top: 2em

      hr
        margin-top: 2em

      .title
        margin-top: 2em

      .button
        margin-top: 2em