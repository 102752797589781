.share-project
  padding     : 56px
  padding-top : 73px

  p
    color         : $gray
    font-weight   : 500
    margin-bottom : 24px

  form
    padding : 0

  .field.floating
    margin-top    : 46px
    margin-bottom : 56px
    text-align    : left

.current-collab
  border-bottom   : 1px solid $light-gray
  border-top      : 1px solid $light-gray
  color           : $gray
  display         : flex
  justify-content : space-between
  padding         : 16px 0

  a
    color       : $teal
    cursor      : pointer
    font-size   : 14px
    font-weight : 600
    line-height : 17px

