$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06)
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7)

fieldset
  background-color: transparent
  border: 0
  margin: 0
  padding: 0

legend
  font-weight: 500
  margin-bottom: calc($small-spacing / 2)
  padding: 0

label
  display: block
  font-weight: 500
  margin-bottom: calc($small-spacing / 2)
  color: $gray

  .description
    font-weight: normal
    max-width: 447px

input,
select,
textarea
  display: block
  font-family: $base-font-family
  font-size: 16px

#{$all-text-inputs}
  appearance: none
  background-color: $base-background-color
  border: $base-border
  border-radius: $base-border-radius
  box-sizing: border-box
  margin-bottom: $small-spacing
  padding: calc($base-spacing / 3)
  transition: border-color $base-duration $base-timing
  width: 100%

  &:hover
    border-color: shade($base-border-color, 20%)

  &:focus
    border-color: $action-color
    box-shadow: $_form-box-shadow-focus
    outline: none

  &:disabled
    background-color: shade($base-background-color, 5%)
    cursor: not-allowed

    &:hover
      border: $base-border

  &::placeholder
    color: tint($base-font-color, 40%)

  &:-webkit-autofill
    -webkit-box-shadow: 0 0 0 30px $soft-white inset !important

textarea
  resize: vertical

[type="checkbox"],
[type="radio"]
  display: inline
  margin-right: calc($small-spacing / 2)

[type="file"]
  margin-bottom: $small-spacing
  width: 100%

select
  margin-bottom: $small-spacing
  width: 100%

[type="checkbox"],
[type="radio"],
[type="file"],
select
  &:focus
    outline: $focus-outline
    outline-offset: $focus-outline-offset

// inputs with floating labels
.field.floating
  border-bottom: 1px solid $light-gray
  position: relative
  margin: 44px 0

  &.error
    border-color: $error-color

    label
      color: $error-color

  #{$all-text-inputs}
    border: none
    border-radius: 0
    box-shadow: none
    margin: 0
    padding: .5em 0

    &:focus,
    &:valid,
    &.valid
      ~ label
        font-size: 12px
        line-height: 14px
        margin: 0
        top: -16px

    &:focus
      ~ .highlight
        width: 100%

  label
    font-weight: 500
    left: 0
    pointer-events: none
    position: absolute
    top: 10px
    transition: 0.3s ease all
    width: 100%

  .highlight
    border-bottom: 4px solid $teal
    bottom: -2px
    content: ''
    left: 0
    position: absolute
    transition: width 0.3s
    width: 0

  .error-message
    color: $error-color
    position: absolute
    right: 0
    top: 10px

// custom checkbox & radio styles
[type="radio"]:not(:checked),
[type="radio"]:checked,
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked
  left: -9999px
  position: absolute

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label,
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label
  cursor: pointer
  padding-left: 40px
  position: relative

[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before
  content: ''
  height: 24px
  left: 0
  position: absolute
  top: 0
  width: 24px

[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before
  border-radius: 100px

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before
  border-radius: 3px

[type="radio"]:not(:checked) + label:before,
[type="checkbox"]:not(:checked) + label:before
  background: #F4F4F4
  border: 1.5px solid #E5E5E5

[type="radio"]:checked + label:before,
[type="checkbox"]:checked + label:before
  background: white
  border: 1.5px solid $teal
  border-color: $teal !important

[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after
  background: $teal
  content: ''
  height: 16px
  left: 4px
  position: absolute
  top: 4px
  transition: all .3s
  width: 16px

[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after
  border-radius: 100px

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after
  border-radius: 3px

[type="radio"]:not(:checked) + label:after,
[type="checkbox"]:not(:checked) + label:after
  opacity: 0
  transform: scale(0)

[type="radio"]:checked + label:after,
[type="checkbox"]:checked + label:after
  opacity: 1
  transform: scale(1)

/* Don't have disabled design yet
/* [type="checkbox"]:disabled:not(:checked) + label:before,
/* [type="checkbox"]:disabled:checked + label:before
/*   box-shadow: none
/*   border-color: #bbb
/*   background-color: #ddd

/* [type="checkbox"]:disabled:checked + label:after
/*   color: #999

/* [type="checkbox"]:disabled + label
/*   color: #aaa

[type="radio"]:checked:focus + label:before,
[type="radio"]:not(:checked):focus + label:before,
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before
  border: 1.5px solid #919191
