.flash-message
  border-radius : 3px
  color         : white
  font-size     : 18px
  margin-bottom : 16px
  min-width     : 635px
  padding       : 10px 16px
  transition    : all 0.3s
  visibility    : visible

  @media only screen and (max-width: 655px)
    min-width : initial
    width     : 90vw

  &.hidden
    height     : 0
    opacity    : 0
    padding    : 0 16px
    visibility : hidden

  &.flash-error
    background : $error-color

  &.flash-info,
  &.flash-success
    background : $success-color

  .container
    margin-left : 0 !important
    width       : 100% !important

  .message
    align-items     : center
    display         : flex
    justify-content : space-between

    .close
      cursor        : pointer

    span
      display: flex
      align-items: center
      justify-content: center

      i
        font-size: 2em
        margin-right: 20px
