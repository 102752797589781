#portfolio
  +container
  padding-top : 56px

  .container
    +grid-column(10)
    +grid-push(2)

    .header
      float : left

  .portfolio-uploads
    align-items     : center
    color           : $medium-gray !important
    display         : flex
    flex-wrap       : no-wrap
    justify-content : space-between
    opacity         : 0.4

  .portfolio-section
    flex : 1
    margin-right : 24px

    &:last-of-type
      margin-right : 0

    .title
      border-bottom  : 1px solid $light-gray
      color          : $dark-gray
      font-size      : 20px
      font-weight    : 500
      line-height    : 24px
      margin-bottom  : 24px
      padding-bottom : 16px

    .upload
      height          : 325px
      border          : 2px dotted #E0E2E8
      border-radius   : $base-border-radius
      text-align      : center
      display         : flex
      flex-direction  : column
      justify-content : center

    .material-icons
      color     : $medium-gray
      font-size : 92px
      opacity   : 0.3

    .md-48
      font-size : 48px

    p
      font-size   : 16px
      line-height : 25px
      text-align  : center

    a
      color           : $medium-gray
      font-weight     : bold
      text-decoration : underline
